<div class="d-grid gap-2 mb-4">
  <button
    class="btn btn-primary"
    type="button"
    (click)="launcInputImageFile()"
    *ngIf="btnLabelType == 'text'">
    {{ btnText | translate }}
  </button>
  <button
    class="btn btn-primary"
    type="button"
    (click)="launcInputImageFile()"
    *ngIf="btnLabelType == 'html'"
    [innerHTML]="btnHtml"></button>
</div>

<input
  #fileInput
  type="file"
  multiple="false"
  [accept]="accept"
  style="display: none"
  (change)="onInputFileChange()" />
