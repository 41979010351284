<div class="alert alert-danger" role="alert">
  <h4
    class="alert-heading"
    [innerHTML]="'noAccess.noPermission' | translate"></h4>
  <p
    [innerHTML]="
      'noAccess.youDontHavePermissionToAccessThisPage' | translate
    "></p>
  <hr />
  <p
    class="mb-0"
    [innerHTML]="'noAccess.pleaseContactYourAdministrator' | translate"></p>
</div>
