<div class="bg-brown">
  <form class="form-grey" [formGroup]="form" novalidate>
    <div class="row align-items-center">
      <!-- Input Collection -->
      <div class="col-12 col-xl-2">
        <label
          for="exampleFormControlInput1"
          class="form-label"
          [innerHTML]="'general.seeProjects' | translate"></label>
        <select class="form-select mb-3" formControlName="collectionId">
          <option
            value=""
            selected
            [innerHTML]="'general.selectAProject' | translate"></option>

          <ng-container *ngIf="collections.length > 0">
            <option *ngFor="let item of collections" [value]="item._id">
              {{ item.name | titlecase }}
            </option>
          </ng-container>
        </select>
      </div>

      <!-- Input Profit -->
      <div class="col-12 col-xl-2">
        <label
          class="form-label"
          [innerHTML]="'projectYourInvestment.return' | translate"></label>
        <input
          type="number"
          class="form-control mb-3"
          formControlName="profit"
          readonly />
      </div>

      <!-- Input Price -->
      <div class="col-12 col-xl-2">
        <label
          class="form-label"
          [innerHTML]="'projectYourInvestment.tokenPrice' | translate"></label>
        <input
          type="number"
          class="form-control mb-3"
          formControlName="price"
          readonly />
      </div>

      <!-- Input NroTokens -->
      <div class="col-12 col-xl-2">
        <label
          for="exampleFormControlInput1"
          class="form-label"
          [innerHTML]="
            'projectYourInvestment.howManyTokensDoYouWant?' | translate
          ">
        </label>

        <div class="number-select-input mb-3">
          <div class="icon-cgp" (click)="addTokens()">
            <i class="bi bi-plus"></i>
          </div>
          <div class="campo-amount">
            <input
              type="text"
              min="0"
              appIntegerInput
              formControlName="nroTokens" />
          </div>
          <div class="icon-cgp" (click)="restTokens()">
            <i class="bi bi-dash"></i>
          </div>
        </div>
      </div>

      <!-- Input Totales -->
      <div class="col-12 col-xl-4">
        <div class="box-form">
          <label for="exampleFormControlInput1" class="form-label">
            {{
              "projectYourInvestment.yourInvestment+EstimatedProfitIs"
                | translate
            }}
            {{ f["gananciaAnual"].value | currency }}
          </label>

          <button
            class="btn btn-main"
            (click)="onSubmit()"
            type="submit"
            [innerHTML]="
              'projectYourInvestment.saveAndFollow' | translate
            "></button>
        </div>
      </div>
    </div>
  </form>

  <div class="row mt-4" *ngIf="listCard">
    <ng-container *ngIf="listCard.product.length > 0">
      <div class="col-12">
        <div class="card-verefication-user-body content-table">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">
                    <span
                      [innerHTML]="'general.projectName' | translate"></span>
                  </th>
                  <th scope="col">
                    <span [innerHTML]="'general.numberOfTokens' | translate">
                    </span>
                  </th>
                  <th scope="col">
                    <span [innerHTML]="'general.investment' | translate"></span>
                  </th>
                  <th scope="col">
                    <span [innerHTML]="'general.percentage' | translate"></span>
                  </th>
                  <th scope="col">
                    <span
                      [innerHTML]="
                        'general.approximateEarnings' | translate
                      "></span>
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="listCard; else loading">
                  <tr *ngFor="let item of listCard.product; let i = index">
                    <th scope="row">
                      <img
                        *ngIf="!item.gallery"
                        src="assets/img/property-img-slider.png"
                        class="img-table"
                        alt="" />
                      <img
                        *ngIf="item.gallery"
                        src="{{ item.gallery[0].url }}"
                        class="img-table"
                        alt="" />
                      {{ item.name }}
                    </th>
                    <td>{{ item.nroTokens }}</td>
                    <td>{{ item.totales | currency }} USD</td>
                    <td>{{ item.estimatedProfitability }}%</td>
                    <td>{{ item.gananciaAnual | currency }} USD</td>
                    <td>
                      <div class="table-buttons">
                        <button class="btn" (click)="edit(i)">
                          <img src="assets/img/edit-icon.png" alt="" />
                        </button>
                        <button class="btn" (click)="clear(i)">
                          <img src="assets/img/delete-icon.png" alt="" />
                        </button>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-12 py-3">
        <div
          class="d-flex justify-content-end align-items-center me-3 me-lg-5 pe-lg-4">
          <p class="text-yellow f-20 me-1">
            {{ "general.totalEarnings" | translate }}:
          </p>
          <p class="text-white f-20">{{ totalGananciasList | currency }}</p>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #noRecords>
  <tr>
    <th scope="row" colspan="7">
      <h5
        class="text-center"
        [innerHTML]="'general.noRecords' | translate"></h5>
    </th>
  </tr>
</ng-template>

<ng-template #loading>
  <tr *ngFor="let item of [1, 1, 1, 1]">
    <th scope="row" colspan="7" class="placeholder-glow">
      <span class="placeholder placeholder-lg col-12"></span>
    </th>
  </tr>
</ng-template>
